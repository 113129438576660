@import "./../../assets/sass/style.scss";

.dashboard_page {
  & > h4.title {
    margin-bottom: 2rem;
  }
  .header {
    margin-bottom: 1rem;
  }
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 300px));
  column-gap: 1rem;
  row-gap: 1rem;
}

.dashboard_card {
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-template-rows: repeat(4, 1fr);
  // background: red;
  border: 1px solid #dadada;
  box-shadow: 1px 1px 1px #dadada;
  border-radius: 12px;
  column-gap: 0.5rem;
  padding: 0.2rem;
  align-items: center;

  &.red {
    .icon {
      background: $state-error;
    }
  }
  &.green {
    .icon {
      background: $state-succes;
    }
  }

  &.blue {
    .icon {
      background: $state-info;
    }
  }

  &.yellow {
    .icon {
      background: $state-warning;
    }
  }
}

.dashboard_card .icon_container {
  grid-row: 1 / span 4;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    // background: $state-info;
    color: #fff;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    padding: 0.5rem;
    font-size: 2rem;
  }
  svg {
    margin: 0;
    height: 1em;
    width: 1em;
  }
}
.dashboard_card .title {
  // font-size: 0.8rem;
}
.dashboard_card .count {
  font-size: 2rem;
  grid-row: 2 / span 2;
}
.dashboard_card .sub {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  svg.plus {
    margin-right: 0.5rem;
    &.up {
      color: $state-succes;
    }
    &.down {
      color: $state-error;
    }
  }
}
.dashboard_card .icon {
}
