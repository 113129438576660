@import "../../assets/sass/variables/index";

.faq-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $brand-primary;
  font-family: "Lato", sans-serif;
  .faqs {
    max-width: 900px;
    padding: 1rem;
    width: 100%;
    margin: auto;
    margin-top: 80px;
    //   background-color: $grey5;
    flex-direction: column;
    text-align: center;
    .faq {
      text-align: start;
      border-bottom: 1px solid $grey4;
      padding: 18px 12px;

      &:last-child {
        border-bottom: 0px;
      }

      .faq-header {
        @extend .md-text-bold;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 24px;
        line-height: 36px;
        cursor: pointer;

        .action {
          cursor: pointer;
        }
      }
      .faq-answer {
        margin-top: 24px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }
    }
  }
}

.faq-margin {
  margin-top: 120px;
}

@media only screen and (max-width: $lg-device) {
  .faq-page {
    .faqs {
      //   /padding: 8px;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .faq-page {
    margin-top: 2rem;
    h3 {
      margin-top: 30px;
      white-space: pre-wrap;
    }
    .faqs {
      padding: 8px;
      .faq-margin {
        margin-top: 2rem;
      }
    }
  }
}
@media only screen and (max-width: $sm-device) {
  .faq-page {
    .faqs {
      margin-top: 2rem;
    
    }
  }
}
