// Brand Colors
$brand-primary: #081149;
$brand-secondary: #fe424d;

//State colors
$state-info: #4e87e3;
$state-succes: #27ae60;
$state-warning: #f2c94c;
$state-error: #e46448;

//Mono colors
$mono-black: #1d1d1d;
$mono-white: #fff;
$mono-grey: #4f4f4f;

//Grey colors;
$grey1: #333333;
$grey2: #4f4f4f;
$grey3: #828282;
$grey4: #bdbdbd;
$grey5: #e0e0e0;
$grey6: #f7f7f7;
