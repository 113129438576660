@import '/src/assets/sass/variables/index';
.table-responsive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: fit-content;
  color: $grey5;
  max-height: 100vh;

  table {
    width: 100%;
    height: fit-content;
    color: black;

    td {
      padding: 12px 18px;
    }
  }
}
@media only screen and (max-width: $sm-device) {
  .table-responsive {
    table {
      td {
        padding: 0.8rem 0.5rem;
        button {
          width: fit-content;
          // white-space: break-spaces;
          padding: 0.25rem;
        }
      }

      td:nth-child(2) {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      td:nth-child(3) {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }
  }
}
