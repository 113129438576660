@import "/src/assets/sass/variables/index";

// .modal-backdrop.show{
//     opacity: 0;
// }
#supportid {
  max-width: 60%;
  margin-left: 25%;
  .modal-content {
    margin-top: 200px;
    max-height: 800px;
    .modal-body {
      padding: 0px;
      width: 100%;
      form {
        padding: 2rem;
        width: 90%;
        margin-top: -105px;
        background-color: white;
        justify-content: center;
        margin-left: 5%;
        border-radius: 8px;
        box-shadow: 0 8px 40px;
        .primary-button {
          border: 1px solid #081149;
          text-align: center;
          padding: 0.5rem;
          color: #fff;
          background-color: #081149;
        }
        .topics__list {
          display: flex;
          flex-wrap: wrap;
          grid-column-gap: 2rem;
          column-gap: 0.5rem;
          row-gap: 0.5rem;
          margin-top: 0.5rem;
          .md-text-bold {
            // margin-bottom: 1rem;
          }
          .md-text-bolds {
            background-color: #081149;
            color: #fff;
          }
        }
        .topic-label {
          margin-top: 1.125rem;
        }
        .messages {
          margin-top: 2.25rem;
        }
        textarea.form-control {
          height: 200px;
        }
      }
      .topics__list:focus {
        background-color: red;
      }
    }
  }
}
.support-notice {
  font-size: 12px;
  line-height: 19.6px;
  font-weight: 400;
  margin-top: 10px;
}
.user_greetings {
  // background-image: url("../../assets/img/background_image.png");
  background: rgb(224, 224, 229);
  background: -moz-linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
  background: -webkit-linear-gradient(
    0deg,
    rgba(224, 224, 229, 1) 0%,
    rgba(154, 155, 172, 1) 100%
  );
  background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);

  background-repeat: no-repeat;
  background-size: auto;
  padding: 1rem;
  height: 220px;

  h6 {
    margin-top: 1rem;
  }
}
.page {
  width: 100%;
  // .page-header {
  //  width: 600px;
  // }

  .hero-sectionn {
    display: flex;
    flex-direction: column;
    height: fit-content;
    // width: 100vw;

    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: -350px;
    .mainn {
      // padding: 42px 200px;
      width: 20vw;
      height: fit-content;
      margin-top: 2.5rem;
      width: 100%;

      // padding-right: 480px;
      h4:nth-child(1) {
        margin-top: 6rem;
      }
      h6 {
        margin-top: 2.5rem;
        white-space: break-spaces !important;
        margin-bottom: 2rem;
      }

      // form{
      //     box-shadow: 0px 8px 40px;
      //     padding:20px;
      //     background-color:white;
      //     border-radius: 8px;

      //     .topics__list{
      //         display: flex;
      //         flex-wrap: wrap;
      //         column-gap: 2rem;
      //         margin-top: 0.5rem;
      //     }
      //     .topic-label{
      //         margin-top: 1.125rem;
      //     }
      //

      //     }
    }
  }
}

@media only screen and (max-width: $md-device) {
  .user_greetings {
    // margin-top: 20px;
  }
  #supportid {
    margin-left: 0;
    max-width: 100%;
    .modal-content {
      margin-top: 80px;
      form {
        width: 100%;
      }
    }
  }

  .mainn {
    margin-top: 00px;
    padding: 1px !important;
    padding-right: 0px !important;
  }
}
