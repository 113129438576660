@import '../../assets/sass/variables/index';

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4ED164;
  color: #4ED164;
  border-color: #4ED164;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  // background-color: $brand-primary;
  color: $brand-primary;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: $brand-primary;
  color: $brand-primary;
  border-color: $brand-primary;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border: #adb5bd solid 1px;
}
