@import '../../assets/sass/variables/index';
#footer {
  padding: 36px 96px 0px 96px;
  border-top: 1px solid $grey5;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 11rem;

  .brand {
    margin-right: 8rem;
    color: $grey2;
  }

  .sitemap {
    padding: 0;
  }
  li {
    list-style-type: none;
    color: $grey1;
    cursor: pointer;
  }
}

.subfooter {
  .btm-mgr {
    margin-bottom: 20px !important;
  }
  padding: 18px 96px;
  flex-direction: row;
  justify-content: space-between;
  color: $grey3;
  margin-bottom: 2rem ;
  

  .disclaimer {
    margin-top: 4rem;
    margin-bottom: 2rem;
    white-space: break-spaces;
    u {
      cursor: pointer;
    }
  }
  .additional-links {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-content: end;
    margin-bottom: 2rem;

    div {
      margin-right: 56px;
    }
  }
}
@media only screen and (max-width: $lg-device) and (min-width: $md-device) {
  .subfooter {
    .disclaimer {
      margin-top: 0px;
      margin-bottom: 16px !important;
      padding-bottom: 16px;
    }
  }
}

@media only screen and (max-width: $sm-device) {
  #footer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem 1.5rem;
    height: fit-content;

    .brand {
      margin-right: 0;
      margin-bottom: -2rem;
      //align-self: center;
    }

    .sitemap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-left: 6em;
    }
  }

  .subfooter {
    flex-direction: column;
    align-items: center;
    padding: 4rem 1.5rem;
    padding-bottom: 1rem;
    justify-content: flex-start;
    height: fit-content;
    padding-top: 0px;
    .disclaimer {
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-size: 12px;
    }

    .additional-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 4rem;

      div {
        margin-right: 8px;
        margin-bottom: 0.5rem;
      }

      div:nth-child(3n) {
        margin-right: 0;
      }
    }
    .btm-mgr {
      margin-bottom: 10px !important;
    }
  }
}
