@import '../../assets/sass/variables';
.card-mar {
  margin-right: 0.7rem;
}

.banner.tertiary {
  background-color: $state-error;
  color: $mono-white;
  align-items: center;
}

@media only screen and (max-width: $sm-device) {
  .banner.tertiary {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.carousel-control-prev{
  display: none;
}

.carousel-control-next{
  display: none;
}