.offcanvas {
  position: fixed;
  top: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  // background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;

  height: calc(var(--vh) * 100);
  max-height: 100vh;
}

.offcanvas-body {
  // .appButton.outlined.primary{
  //     display: flex;
  //     position: absolute;
  //     bottom:55px;
  //     font-size: 20px;
  //     font-weight: 700;
  // }
  // .appButton.primary{
  //     display: flex;
  //     position: absolute;
  //     bottom: 0;
  //     font-size: 20px;
  // }
}

.active-mob {
  // margin-top: 0.5rem;
  // margin-left: -2rem;
  border-left: 4px solid #081149;
  background-color: #f7f7f7;
  padding-left: 24px;
  // padding-top: 8;
  // padding-bottom: 8;
}

.mobile-deactive {
  margin-top: 0.5rem;
  // margin-left: -2rem;
  color: #081149;
  padding-left: 28px;
  padding-top: 8px;
  display: flex;
  padding-bottom: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ul-sidebarhome{
  padding-left: 0;
}
