@import "../../assets/sass/variables/index";

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}
.active-mob {
  // margin-top: 0.5rem;
  // margin-left: -2rem;
  border-left: 4px solid #081149;
  background-color: #f7f7f7;
  padding-left: 24px;
  // padding-top: 8;
  // padding-bottom: 8;
}

.mobile-deactive {
  margin-top: 0.5rem;
  // margin-left: -2rem;
  color: #081149;
  padding-left: 28px;
  padding-top: 8px;
  display: flex;
  padding-bottom: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.nav-link-separator {
  margin-top: 1.2rem;
}

.bm-menu-wrap {
  position: fixed;
  right: 0px;
  z-index: 1100;
  width: 300px;
  height: 100%;
  transition: all 0.5s ease 0s;
  top: 0px;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 27px;
  height: 21px;
  right: 1rem;
  top: 1rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #5e5e5f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-burger-padding {
  padding-left: 1.5em;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #ffff;
  padding: 2.5em 0em 0;
  font-size: 1.15em;
}

.bm-menu1 {
  background: #ffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #081149;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.ul-hamburger {
  padding-left: 0rem;
}

// .active {
//     // background-color: #f29c06;
//     // padding: 0% 1%;
//     color:#FE424D;
//     text-decoration: underline !important;
//     // border: 1px solid #f29c06;
//     box-shadow: 4px 3px 4px rgb(33, 25, 35);
//     transition: all 1s ease-out, visibility 0s, delay 7s;
//   }
