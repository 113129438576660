.player {
  display: flex;
  justify-content: center;

  .video-container {
    position: relative;
    width: auto;
    height: auto;
    display: grid;
    place-items: center;
    &:after {
      content: "";
      height: 100%;
      width: 5px;
      right: 0;
      background: white;
      position: absolute;
      right: 0px;
    }
  }

  video {
    clip-path: inset(5px 5px);
    &.video_player {
      width: 60vw;
      max-width: 400px;
      height: auto;

      @media screen and (min-width: 600px) {
        width: 40vw;
      }
    }
  }
}
