@mixin responsive($px) {
  @media all and (min-width: $px) {
    @content;
  }
}

.table_container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: auto;
  scrollbar-color: #000;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    cursor: pointer;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 8px;
  }
}

.table {
  th,
  td {
    text-align: center;
  }

  tbody {
    border: 1px solid rgba(8, 17, 73, 0.4);
  }
}

.pagination_size_fieldset {
}
.pagination_size {
  border-radius: 12px;
  border: 0.5px solid rgba(197, 197, 197, 0.517);
  .pagination_size_option {
  }
}

.filter_input_container {
  position: relative;
  .icons {
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 1rem;
    color: rgba(95, 92, 92, 0.366);

    &:last-child {
      left: unset;
      right: 1px;
    }
  }
}
.filter_input {
  width: 100%;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(197, 197, 197, 0.517);
  padding: 0.5rem 1rem;
  padding-left: 2rem;

  &.normal {
    padding: 0.5rem;
  }
}

.filter_button {
  margin-right: 1rem !important;
  display: block;
  font-size: 0.8rem;
  // height: 1.8rem;
  // width: 1.8rem;
  padding: 0;
  svg {
    margin: 0;
  }
}

.filter_container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  gap: 2rem;
  row-gap: 1rem;
  padding: 0 0.8rem;
  margin-bottom: 1rem;

  @include responsive(600px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.css-1320iog-MuiTableCell-root {
  vertical-align: baseline;
}

.tableHeader {
  color: white;
  position: relative;
}

@media screen and (max-width: 500px) {
  .tableHeadAndBody {
    max-width: 300px;
    overflow-x: scroll;
    margin: auto;
    .tableHeader {
      color: white;
    }
  }
}
@media screen and (min-width: 1280px) {
  .tableHeadAndBody {
    width: 60vw;
    overflow-x: scroll;
    margin: auto;
    min-height: 500px;

    .tableHeader {
      min-width: 140px;
      max-width: 180px;
      color: white;
    }
  }
}

// @media screen and (min-width: 1440px) {
//   .tableHeadAndBody {
//     max-width: 850px;
//     overflow-x: scroll;
//     margin: auto;
//     .tableHeader {
//       min-width: 160px;
//     }
//   }
// }
