@import "/src/assets/sass/variables/index";

.account-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 36px 8rem;
  justify-content: center;

  .sidemenu {
    padding: 0px 100px 0 0;
    position: fixed;
    left: 9%;

    ul {
      padding-left: 0;
    }
    li {
      padding: 12px 0px;
      list-style-type: none;
      cursor: pointer;
    }
  }

  .forms-container {
    padding: 8px 24px;
    width: 50vw;
    .form-container {
      form {
        margin: 24px;

        .action-box {
          display: flex;
          flex-direction: row;
          justify-content: end;
        }
      }
    }

    label {
      font-size: 14px;
    }
  }

  .appCard {
    border: 0px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    background-color: $grey6;
    padding: 24px 24px;
  }
  .subCardInnerAlign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .subCardInnerBtn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .funding-info,
  .subscription-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;

    .card-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
.switch-position {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delete-ac {
  margin-top: 32px;
  width: 100%;
}

@media only screen and (max-width: $md-device) {
  .account-page {
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-items: center;

    li {
      padding: 0;
    }

    .forms-container {
      width: 100%;
      padding: 8px;

      .form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        form {
          margin: 0px;

          h5 {
            align-self: flex-start;
          }
          .action-box {
            justify-content: center;
            margin-bottom: 32px;
          }
        }
      }
    }
    // .appCard {
    //   align-items: center;
    // }

    .sidemenu {
      display: none;
    }
  }
}

.jumper {
  width: 100%;
}

@media screen and (min-width: $sm-device) {
  .jumper {
    display: none;
  }
}

.remove-card {
  border-radius: 12px;
  border: none;
  color: #fff;
  background: $brand-secondary;
  svg {
    margin: 0.8rem 1rem;
  }
}
