@import '../../assets/sass/variables/index';

.policy-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $brand-primary;
  font-family: 'Lato', sans-serif;

  .policy {
    padding: 120px 360px;
    //   background-color: $grey5;
    flex-direction: column;
    text-align: center;

    h5 {
      text-align: left;
      text-transform: uppercase;
      list-style-type: decimal;
    }
    p {
      text-align: left;
    }

    ol {
      text-align: left;
      left: 50vw;

      li {
        text-decoration: underline;
        padding: 0;
        list-style-type: decimal;
        color: $state-info;
        cursor: pointer;
      }
    }

    ul {
      text-align: left;
      left: 50vw;

      li {
        text-decoration: none;
        list-style-type: disc;
      }
    }
  }
}

@media only screen and (max-width: $lg-device) {
  .policy-page {
    .policy {
      //   /padding: 8px;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .policy-page {
    margin-top: 0px;
    h3 {
      margin-top:40px;
      white-space: break-spaces;
      
    }
    h5{
      white-space: break-spaces;
    }
    .policy {
      padding: 8px;
      word-wrap: break-word
    }
  }
}
