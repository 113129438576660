@import "../../assets/sass/variables";

.page {
  .hero-sectionn {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    // background-image: url("../../assets/img/background_image.png"); consistent gradient
    background: rgb(224, 224, 229);
    background: -moz-linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    background: -webkit-linear-gradient(
      0deg,
      rgba(224, 224, 229, 1) 0%,
      rgba(154, 155, 172, 1) 100%
    );
    background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);

    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -350px;
    .mainn {
      @media screen and (max-width: $md-device) {
        margin-top: 4.5rem;
      }

      padding: 42px 200px;
      width: 100%;
      height: fit-content;
      margin-top: 2.5rem;
      padding-right: 480px;
      h4:nth-child(1) {
        margin-top: 6rem;
      }
      h6 {
        margin-top: 2.5rem;
        white-space: break-spaces;
        margin-bottom: 2rem;
      }
      form {
        box-shadow: 6px 6px 19px 0px #0811495c;
        //    box-shadow: 20px 20px 20px 20px;
        padding: 20px;
        background-color: white;
        border-radius: 8px;
        .primary-button {
          border: 1px solid #081149;
          text-align: center;
          padding: 0.5rem;
          color: #fff;
          background-color: #081149;
        }

        .topics__list {
          display: flex;
          flex-wrap: wrap;
          grid-column-gap: 2rem;
          column-gap: 0.5rem;
          row-gap: 0.5rem;
          margin-top: 0.5rem;
          .md-text-bold {
            // margin-bottom: 1rem;
          }
          .md-text-bolds {
            background-color: #081149;
            color: #fff;
          }
        }
        .topic-label {
          margin-top: 1.125rem;
        }
        textarea.form-control {
          height: 200px;
        }
      }
      .topics__list:focus {
        background-color: red;
      }
    }
  }
  .contact-notice {
    font-size: 12px;
    line-height: 19.6px;
    font-weight: 400;
    margin-top: 10px;
  }
}

@media only screen and (max-width: $md-device) {
  .mainn {
    padding: 1px !important;
    padding-right: 0px !important;
  }
}
.sdpadding {
  padding-left: 0.5rem;
}

.contact-mar {
  margin-left: -1rem;
}
