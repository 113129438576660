@import '../../assets/sass/variables/index';

select {
  max-width: 100%;
  -webkit-appearance: block;
  display: block;
  padding: 8px;
  border: 1px solid $grey1;

  option {
    padding: 12px;
  }
}
