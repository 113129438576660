@import '/src/assets/sass/variables/index';
.subs-page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  background-color: $grey6;
  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: $grey6;
    padding: 48px 72px;
    position: relative;

    .form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 16px 0px;
      position: relative;

      .card {
        padding: 24px 12px;
        margin: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100vw / 5);
        cursor: pointer;
        position: relative;

        .row {
          white-space: break-spaces;
        }

        .label {
          margin: 0px 12px;
          text-transform: capitalize;
          h6 {
            white-space: pre-wrap;
          }
        }
      }
    }

    .title {
      margin-bottom: 64px;
      align-self: center;
    }

    section {
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      padding: 0;
      margin: 24px 0;
    }

    label {
      color: $mono-grey;
    }
    .sm-text-reg {
      @extend .sm-text-reg;
      color: $mono-grey;
      padding-bottom: 12px;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .subs-page {
    .container {
      padding: 16px;
      .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card {
          padding: 24px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: $xl-device) {
  .subs-page {
    .container {
      padding: 56px 120px;
      .form {
        .card {
          padding: 24px;
          width: 100%;
        }
      }
    }
  }
}
