@import '../../assets/sass/variables/index';
@import '/src/assets/sass/variables/index';

.onboarding-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #081149;
  white-space: initial;
  h2 {
    line-height: 53px;
  }
}
.onboarding-text-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d1d;
  margin-top: 48px;
  font-size: 24px;
  line-height: 36px;
}
.later-btn {
  margin-bottom: 2rem;
}

.onboarding-space {
  margin-top: 6rem;
  text-align: center;
}
.onboarding-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 96px;
}
.onboarding-text-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
  text-decoration: underline;
}
.app-btn {
  width: 251px;
  height: 56px;
  background: #081149;
}
.app-btntxt {
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.box {
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // padding: 0px;

  justify-content: center;

  // position: absolute;
  // width: 700px;
  //height: 536px;
  //left: 23%;
  //margin: 2rem 4rem 0rem 4rem;
}
.spc {
  left: 100px;
}
.ftext {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.onboarding-space2 {
  margin-top: 10vh;
  .onboarding2-btn {
    display: flex;
    justify-content: center;
  }
}
.onboarding-space3 {
  margin-top: 16px;
}
.box3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  .onboarding3-btn {
    display: flex;
    justify-content: space-between;
  }

  // position: absolute;
  // width: 700px;
  // height: 321px;
  // left: 29%;
}

.card1 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  /* Brand/Primary */

  color: #081149;
}

.card3-spc {
  margin-top: 24px;
  text-align: left;
}

@media only screen and (max-width: $md-device) {
  .onboarding-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box {
    // margin: 0rem 2rem 0rem 2rem;
    .onboarding-text-1 {
      display: flex;
    }
  }
  .onboarding-space2 {
    .onboarding2-btn {
      display: flex;
      justify-content: center;
    }
  }
  .box3 {
    margin: 0rem 2rem 0rem 2rem;
    text-align: left;
    .onboarding3-btn {
      display: flex;
      flex-direction: column-reverse;
      row-gap: 2rem;
    }
  }
}
