@import '../../assets/sass/variables/index';
.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  .contain1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 836px;
    height: 600px;
    margin-top: 152px;
    background-color: #f5f5f5;
    border-radius: 9px;
  }
  .stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }
  .textarea {
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin: 39px 0;
    height: 114px;
    width: 330px;
    border-radius: 7px;
    background-color: #ffffff;
  }
  .button {
    border: 0px solid #ffffff;
    width: 251px;
    height: 33px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 255, 0.72) 27.29%,
      rgba(135, 206, 250, 0.74) 98.8%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .first-message {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 33px;
  }
  .second-message {
    font-family: Inter;
    font-size: 26px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 70px;
    color: #000000;
  }
  .third-message {
    font-family: Inter;
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #ffffff;
  }
  .contain2 {
    height: 435px;
    width: 644px;
    //top: 238px;
    border-radius: 0px;
    background-color: #cbcbcb40;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 23px;
  }
  .button2 {
    margin-top: 23px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    height: 20px;
    width: 124px;
    border: 0px solid;
    background-color: #f5f5f5;
    border-radius: 0px;
    color: #00008080;
  }

  .change::placeholder {
    color: #bbbbbb;
    opacity: 1; /* Firefox */
    font-family: Inter;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
  }
}
@media only screen and (max-width: $md-device) {
  .contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    .contain1 {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 344px;
      height: 385px;
      margin-top: 152px;
      background-color: #f5f5f5;
      border-radius: 9px;
    }
    .stars {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .textarea {
      border: 1px solid #ffffff;
      border-radius: 5px;
      padding: 10px;
      margin: 20px 0;
      height: 53px;
      width: 224px;
      border-radius: 7px;
      background-color: #ffffff;
    }
    .button {
      border: 0px solid #ffffff;
      width: 251px;
      height: 33px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 255, 0.72) 27.29%,
        rgba(135, 206, 250, 0.74) 98.8%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .first-message {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
      margin-top: 33px;
    }
    .second-message {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 19px;
      color: #000000;
    }
    .third-message {
      font-family: Inter;
      font-style: normal;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #ffffff;
    }
    .contain2 {
      height: 235px;
      width: 344px;
      top: 238px;
      border-radius: 0px;
      background-color: #cbcbcb40;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-top: 23px;
    }
    .button2 {
      margin-top: 23px;
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      height: 16px;
      width: 74px;
      border: 0px solid;
      background-color: #f5f5f5;
      border-radius: 0px;
      color: #00008080;
    }

    .change::placeholder {
      color: #bbbbbb;
      opacity: 1; /* Firefox */
      font-family: Inter;
      font-size: 8px;
      font-weight: 500;
      line-height: 10px;
    }
  }
}
