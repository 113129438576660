@import '/src/assets/sass/variables/index';

.card-input {
  font-family: $card-font-stack;
  color: $mono-white;
  //background-image: url('../../assets/img/card-layer.png');
  background-color: $brand-primary;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 16rem;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 420px;
  min-width: $xs-device;

  align-self: center;
  height: 15rem;

  label {
    font-family: $card_secondary-stack;
    font-weight: 400;
  }

  .card-input-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .card-input-body {
    padding: 36px 12px;
    align-content: center;
    display: flex;
    flex-direction: column;
    input {
      font-size: 24px;
      background: $brand-primary;
      color: $mono-white;
      border: none;
      min-height: 24px;
      text-align: center;
      padding: 0px 12px;
      outline: $mono-white;
    }
    input:focus {
      text-align: left;
    }

    .invalid-feedback-text {
      margin-top: 4px;
      text-align: center;
    }
  }

  .card-input-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .form-group {
      width: 30%;

      input {
        appearance: 'textfield';
        font-size: 24px;
        width: 100%;
        background: $brand-primary;
        color: $mono-white;
        border: none;
        min-height: 24px;
        font-family: $card-font-stack;
        outline: $mono-white;
      }

      #cvc {
        text-align: right;
      }
      #cvc:focus {
        text-align: left;
      }
    }

    // .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    //   padding: 0px;
    // }
    // .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
    //   padding: 0;
    // }
    // #cvc {
    //   text-align: right;
    // }
    // #cvc:focus {
    //   text-align: left;
    // }
    // #cvc::placeholder {
    //   text-align: right;
    // }
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// .card-input-flipped {
//   @extend .card-input;
//   background-image: url('../../assets/img/card-layer.png') 0 0 repeat;

//   transition: 1s ease;
// }

.card-input-flipped {
  @extend .card-input;
  height: fit-content;
  #sameInfo {
    float: none;
    vertical-align: middle;
  }
  .card-input-body-flipped {
    // padding: 46px 76px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    input {
      width: 100%;
      font-size: 18px;
      background: $brand-primary;
      color: $mono-white;
      border: none;
      margin: 14px 0px;
    }

    select {
      font-size: 18px;
      background: $brand-primary;
      color: $mono-white;
      border: none;
      margin: 8px 0px;
      outline: $mono-white;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .card-input {
    font-size: 12px;
    // height: 14rem;

    .card-input-body {
      padding: 22px 36px;
      input {
        font-size: 16px;
        width: 100%;
      }
    }
    .card-input-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .form-group {
        width: 100%;
      }

      input {
        appearance: 'textfield';
        font-size: 12px;
        width: 80%;
        background: $brand-primary;
        color: $mono-white;
        border: none;
      }
    }
  }

  .card-input-flipped {
    // padding: 46px 76px;
    background-image: none;
    height: fit-content;
    font-size: 12px;

    #sameInfo {
      float: none;
      vertical-align: bottom;
    }

    .card-input-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // color: $mono-grey;
    }
    label {
      font-size: 12px;
      color: $mono-white;
    }

    .card-input-body-flipped {
      input {
        font-size: 12px;
      }
      select {
        font-size: 12px;
        margin: 8px 0px;
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}

@media only screen and (max-width: $xs-device) {
  .card-input {
    font-size: 12px;
    // height: 14rem;
    max-width: 100%;
    min-width: 100%;
    .card-input-body {
      padding: 22px 40px;
    }
    .card-input-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        appearance: 'textfield';
        font-size: 12px;
        width: 80%;
        background: $brand-primary;
        color: $mono-white;
        border: none;
      }
    }
  }

  .card-input-flipped {
    // padding: 46px 76px;
    background-image: none;
    font-size: 12px;
    .card-input-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // /color: $mono-black;
    }

    .card-input-body-flipped {
      input {
        font-size: 12px;
        // background: $mono-white;
        // color: $mono-black;
        // border: 1px solid $grey4;
      }

      select {
        font-size: 12px;
        // background: $mono-white;
        // color: $mono-black;
        margin: 8px 0px;
        //  / border: 1px solid $grey4;
      }
    }
  }
}
