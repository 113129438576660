@mixin resp($px) {
  @media screen and (min-width: $px) {
    @content;
  }
}

.adminportal-page {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  padding: 1rem;

  padding-top: 8rem;
  // margin-top: 4rem;
  margin-bottom: 10rem;
  .sidebar-admin {
    display: flex;
    // align-items: center;
    flex-direction: column;
  }
  .main-container-admin {
    display: flex;
    // justify-content: center;
    flex-direction: column;
    // align-items: center;
    width: 100%;
  }

  @include resp(990px) {
    max-width: 85vw;
    grid-template-columns: 200px minmax(500px, 1fr);
    margin: auto;
  }
}

.admin-navbar {
  width: 100%;
  top: 1rem;
  position: fixed;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: hsla(0, 0%, 100%, 0.9);
}
.admin-navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  // margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.admin-sidbar-item {
  padding: 12px 0;
  list-style-type: none;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .adminportal-page {
    grid-template-columns: unset;
    padding-top: 4rem;

    .sidebar-admin {
    }
    .main-container-admin {
      grid-column-start: 1;
      // margin-left: 1rem;
    }
  }

  .admin-navbar {
    padding: 0 1rem;
  }
}
