@import '/src/assets/sass/variables/index';

.notification-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;

  li {
    cursor: pointer;
    width: fit-content;
    padding: 4px;

    label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding: 0 8px;
      color: $mono-black;

      &.active {
        font-weight: 800;
        color: $brand-primary;
      }
    }
  }
}
