@import '/src/assets/sass/variables/index';
.dropdown {
  align-self: center;
  padding: 0px 4px;
  width: fit-content;
  border: 1px solid $grey4;
  border-radius: 4px;
  list-style-type: none;
  color: $grey4;
  cursor: pointer;
  a:link,
  :visited,
  :hover,
  :target,
  :focus-visible {
    text-decoration: none;
    color: $brand-primary;
    // /padding-bottom: 8px;
    // align-self: center;
  }

  a:active:hover {
    background-color: $brand-primary;
    color: $grey6;
  }

  .nav-link.active {
    border-bottom: 4px solid $brand-primary;
    border-left: none;
  }

  .dropdown-menu {
    @extend .sm-text-reg;
    line-height: 45px;
    height: fit-content;

    svg {
      width: 20px;
      height: 20px;
    }

    #signout {
      color: $brand-secondary;
    }
  }

  &.active {
    border-bottom: 4px solid $brand-primary;
    border-left: none;
  }

  #navbarDropdown {
    padding: 4px;
    // color: $grey4;
  }

  .iconify,
  .iconify:hover {
    float: right;
    font-size: 24px;
    color: #4f4f4f;
    margin: 0;
    transform: none;
  }

  .dropdown-toggle::after {
    display: none;
  }

  //   .dropdown {
  //     padding: 0px 8px;
  //     width: fit-content;
  //     border: 1px solid $grey4;
  //     border-radius: 4px;
  //   }

  .dropdown-menu {
    margin: 0 1rem;
    @extend .sm-text-reg;
    line-height: 45px;
    height: fit-content;

    svg {
      width: 20px;
      height: 20px;
    }

    #signout {
      color: $brand-secondary;
      svg {
        color: $brand-secondary;
      }
    }
  }

  .dropdown-item {
    display: flex;
    height: 2.5rem;
    align-items: center;

    &.active {
      background-color: $brand-primary;
      color: $mono-white;
    }
    &:hover,
    &:active {
      background-color: #e9ecef;
      color: $mono-black;
    }
  }
}
