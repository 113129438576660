.spinner-position {
  //   display: flex;
  //   justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    transform: unset;
  }

  img {
    width: 50px;
    height: auto;
  }
}

.small-spinner {
  img {
    display: block;
    width: 30px;
    height: auto;
  }
}
