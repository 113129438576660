@import "../../assets/sass/variables/index";

.carousel-caption {
  top: 72px;
}

.carousel-item {
  height: 60vh;
}

.carousel-img {
  display: block;
  width: 100%;
  height: 70%;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  background-color: $grey5;
  height: 70%;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  //height: 427px;
  height: 100%;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: -2rem 15%; //TLBR
  // margin-right: 15%;
  // margin-bottom: -0.5rem;
  // margin-left: 15%;
  list-style: none;
  li {
    border-radius: 50%;
    //transform: rotate(133deg);
    width: 12px;
    height: 12px;
    background-color: black;
    justify-content: space-between;
    margin: 0px 24px !important;
  }
}

// .carousel-indicators:nth-child(2) {
//   margin-bottom: 9em;
// }

// .carousel-indicators2 {
//   //position: absolute;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 2;
//   display: flex;
//   justify-content: center;
//   padding: 0;
//   margin-right: 15%;
//   margin-bottom: -1rem;
//   margin-left: 15%;
//   list-style: none;
//   li {
//     border-radius: 50%;
//     width: 12px;
//     height: 12px;
//     background-color: black;
//     justify-content: space-between;
//   }
// }

// .carousel-indicators li,
.carousel-indicators li.active {
  // margin: 0px 29px !important;
  border-radius: 50%;
  transform: none;
}

// .carousel-indicators2 li, .carousel-indicators2 li.active {
//   margin: 0px 29px !important;
// }

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: $brand-primary;
  height: 100%;

  .carousel-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    color: $brand-primary;
    text-align: center;
  }
}

.carbg {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  // text-align: center;
  margin-left: 26px;
  // flex-direction: row;
  // flex-wrap: wrap;
  // justify-content: center;

  color: #081149;

  width: 370px;
  height: 44px;
  left: 342px;
  top: 103px;
}
.carcon {
  font-size: 24px;
  line-height: 36px;
  /* or 150% */

  text-align: center;

  /* Mono/Gray 1 */

  color: #333333;

  width: 430px;
  height: 144px;
  left: 315px;
  top: 179px;
}
// .carimg {
//   // width: 296px;
//   // height: 158.91px;
//   // width: 100%;
//   // height: 100%;
//   // align-items: center;
//   // align-content: center;
//   // align-self: center;
//   // left: 200px;
//   // top: 355px;
//   // margin-left: 60px;
// }

.carwho {
  @extend .carcon;
  font-size: 18px;
}

.carficoimg {
  width: 322px;
  height: 235px;
  left: 369px;
  top: 303px;
  margin-left: 60px;
}

@media only screen and (max-width: $sm-device) {
  .carousel-img {
    display: none;
  }

  .carousel-item {
    height: fit-content;
  }

  .carousel-content {
    width: 100%;

    .carousel-text {
      width: 100%;
    }
  }
}

//carousel indicators for smaller device
@media only screen and (max-width: 375px) {
  .carousel-indicators {
    li {
      margin: 0px 22px !important;
    }
  }
}
