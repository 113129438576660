@import "../../assets/sass/variables";
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: $brand-primary;
  font-family: "Lato", sans-serif;
  .getting-started {
    display: flex;
    flex-direction: column;
    text-align: center;

    .getting_started__img {
      max-width: 100%;
    }
  }

  .hero-section {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    // background-image: url("../../assets/img/background_image.png"); Gradient image
    background-repeat: no-repeat;
    background-size: cover;
    background: rgb(224, 224, 229);
    background: -moz-linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    background: -webkit-linear-gradient(
      0deg,
      rgba(224, 224, 229, 1) 0%,
      rgba(154, 155, 172, 1) 100%
    );
    background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);

    .main {
      padding: 42px 100px;
      width: 100%;
      height: fit-content;

      .main-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: fit-content;

        .secondary {
          color: $brand-secondary;
        }

        .hero-img {
          width: 50%;
          height: 50%;
          align-items: center;
        }
        .containerr {
          // background-image: url('../../assets/img/background_image.png');
          // background-repeat: no-repeat;
          // background-size: auto;
          // z-index: 9999;
        }
        .hero-img-mobile {
          display: none;
        }
        .containerrmob {
          display: none;
        }
        .sponsors-mobile {
          display: none;
        }

        .content {
          margin: 0px;
          //margin-top: 104px;
          margin-top: 152px;
          // margin-right: 368px;
          min-width: 50%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          // .sponsors{
          //   margin-bottom: 3rem;
          // }
          // .appButton.primary{
          //   margin-bottom: 3rem;

          // }

          h1 {
            white-space: break-spaces;
            text-align: start;
            font-weight: 400;
            line-height: 3.75rem;
            font-size: 2.5rem;
          }

          p {
            padding-top: 22px;
            font-size: 18px;
            font-weight: 400;
            line-height: 36px;
            padding-bottom: 32px;
          }

          li {
            @extend .nrm-text-reg;
            list-style-type: initial;
            padding: 6px 0px;
            line-height: 14px;
          }

          .sponsors {
            height: 40px;
            //margin: 147px 0px 12px 0px;
            margin-top: 98.61px;
            width: 55%;

            @media screen and (max-width: $md-device) {
              width: 100%;
              height: auto;
            }
            @media screen and (max-width: $sm-device) {
              width: 57%;
              height: auto;
            }
          }
          .btn-homepg {
            margin-top: -1rem;
          }
        }
        .input-group {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          align-items: stretch;
          width: 100%;
          flex-direction: row;
        }

        img {
          // margin-left: 30px;
          width: 480px;
          height: 560px;
          //margin-top: 50px;
          //position: absolute;
          /*width: 915.56px;
          height: 553.38px;
          left: 794px;
          top: 19px;*/
        }
      }
    }
  }
  .txt-align {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
  }
  // .sponsors{
  //   margin-top: 148.61px;
  // }
  .meet-pria {
    margin-top: 5rem;
    margin-bottom: 6rem;
    .meet-pria__text {
      margin-bottom: 2.5rem;
      text-align: center;
      font-size: 2.5rem;
      line-height: 44px;
      //margin-right: 2rem;
    }
    .meet-pria__img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 32%;
      max-width: 70vmin;
      height: auto;
    }
    .meet-pria__text2 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 400;
      max-width: 80%;
      margin: auto;
      white-space: break-spaces;

      @media screen and (max-width: $sm-device) {
        font-size: 1rem;
        max-width: 90%;
      }
    }
  }

  .top-section {
    margin-top: 2.5em;
  }
  .top-text1 {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }

  section {
    display: flex;

    //padding: 142px 240px 96px 240px;

    //border-bottom: 1px solid $grey5;

    #arrow {
      //sposition: absolute;
      width: 40px;
      height: 8%;

      align-self: center;
    }

    .illustrations {
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: 270px;
      align-items: center;

      img {
        margin-bottom: 30px;
        max-width: 60px;
      }
    }
    // .ftr-group1 {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-between;
    //   width: 100%;
    //   // padding-inline-start: .5em;
    //   // padding-inline-end: .5em;
    //   .ftr1 {
    //     padding: 8px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     text-align: center;
    //     width: 100%;
    //   }
    //   .ftr2 {
    //     padding: 8px 100px 8px 100px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     text-align: center;
    //     width: 100%;
    //   }
    // }
    .ftr-group {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .ftr {
        padding: 20px 0px 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        .ftr-image {
          width: 40%;
        }
        .ftr-imageMobile {
          width: 20%;
        }
        .illustrations {
          max-width: 190px;
          .h5 {
            white-space: break-spaces;
          }
        }
      }
      .ftr1 {
        padding: 20px 10px 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        .ftr-image {
          width: 40%;
        }
        .ftr-imageMobile {
          width: 20%;
        }
        .illustrations {
          max-width: 190px;
          .h5 {
            white-space: break-spaces;
          }
        }
      }
      .ftr2 {
        padding: 30px 0px 8px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
      }
      .frame {
        display: flex;
        flex-direction: column;
        text-align: start;
        margin-left: 225px;
        margin-top: 208px;
        .p-block {
          white-space: break-spaces;
          h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 44px;
            color: #ff9200;
          }
          margin: 14px 0px;
        }
      }
    }

    li {
      list-style-type: initial;
      padding: 6px 12px;
      line-height: 14px;
    }
    .logo {
      width: 372px;
      height: 372px;
    }
    .context {
      margin-left: 120px;
    }
  }
  section:nth-child(2n) {
    // @extend section;
    //background-color: $grey6;
    //margin-bottom: 142px;
    // flex-direction: row-reverse;
  }
  // .faqs {
  //   padding: 63px 320px;
  //   background-color: $grey5;
  //   flex-direction: column;
  //   text-align: center;
  //   .faq {
  //     text-align: start;
  //     border-bottom: 1px solid $grey4;
  //     padding: 18px 12px;
  //     .faq-header {
  //       @extend .md-text-bold;
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: space-between;

  //       .action {
  //         cursor: pointer;
  //       }
  //     }
  //   }
  // }
  .pria-temp-image {
    width: 50vw;
    height: auto;
  }

  .end-banner {
    width: 100%;
    padding: 72px;
    background-color: $brand-primary;
    color: $mono-white;
    align-items: center;
    text-align: center;
    .appButton.outlined.primary {
      width: 10rem;
      height: 3rem;
    }
    h4 {
      margin-bottom: 1rem;
    }
  }
  // .activity-block {
  //   width: 30%;
  //   height: 3px;
  //    margin-left: 7%;
  //   margin-top: 6.7%;
  //   background: #e0dede;
  // }
  // .activity-block1 {
  //   width: 30%;
  //   height: 3px;
  //   margin-left: 35%;
  //   margin-top: 6.9%;
  //   background: #e0dede;
  // }
  .activity-block {
    width: 8.5rem;
    height: 3px;
    //  margin-left: 7%;
    margin-top: 2px;

    background: #e0dede;
  }
  .activity-block1 {
    width: 8.5rem;
    height: 3px;
    /* margin-left: 30%; */
    margin-top: 2px;
    background: #e0dede;
    /* display: inline-flex; */
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.black-cardimg {
  // width: 220.09px;
  // height: 110.2px;
  // width: 150.09px;
  // height: 80.2px;
  // width: 130.09px;
  // height: 65.2px;
  width: 85.09px;
  height: 50.2px;
  margin-top: 0.2rem;
}
.fico-cardimg {
  // width: 230.09px;
  // height: 162.2px;
  // width: 150.09px;
  // height: 107.2px;
  width: 130.09px;
  height: 90.2px;
}
.score-cardimg {
  // width: 265.09px;
  // height: 160.2px;
  // width: 230.09px;
  // height: 135.2px;
  width: 133.09px;
  height: 61.2px;
  margin-top: 2.3rem;
}

.error-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 128px;
  align-items: center;
}
.error-page1 {
  white-space: pre-wrap;
}

.top-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-style: Normal;
  size: 16px;
  letter-spacing: 2px;
  //Text Transform: Uppercase
  line-height: 1.2em;
  font-family: $card_tertiary_stack;
  margin-top: 2.5rem;
  margin-bottom: 0;
}
.top-banner1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-style: Normal;
  size: 16px;
  letter-spacing: 2px;
  //Text Transform: Uppercase
  line-height: 2em;
  font-family: Proxima Nova;
  margin-bottom: 3rem;
  //margin-top: -1rem;
}

@media only screen and (max-width: $md-device) {
  .page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: $brand-primary;

    .ftr-group {
      display: flex;
      flex-direction: column;
      padding: 0px;
      .illustrations {
        margin-bottom: 0px !important;
      }
      img#arrow {
        display: none;
        padding: 0px;
      }
    }

    .hero-section {
      display: flex;
      flex-direction: column;
      padding: 30px 30px;
      align-items: flex-start;
      width: 100%;
      // background-image: url("../../assets/img/newHero.png");
      background: rgb(224, 224, 229);
      background: -moz-linear-gradient(
        0deg,
        rgba(224, 224, 229, 1) 0%,
        rgba(154, 155, 172, 1) 100%
      );
      background: -webkit-linear-gradient(
        0deg,
        rgba(224, 224, 229, 1) 0%,
        rgba(154, 155, 172, 1) 100%
      );
      background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);

      background-repeat: no-repeat;
      background-size: auto;

      //background: #1aa6b7;

      main {
        padding: 10px 10px;
        position: relative;
        height: 50vh;
        width: 100%;
        .main-container {
          // width: 100vw;
          // height: 100vh;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .hero-img {
            width: 100%;
            height: 80%;
            align-items: center;
          }

          .content {
            margin: 0px;
            width: 100%;
            padding: 10px 10px;
            height: 100vh;
            p {
              padding-top: 32px;
              font-size: 16px;
              font-weight: 400;
              line-height: 36px;
              padding-bottom: 32px;
            }
            .input-group {
              position: relative;
              display: flex;
              flex-wrap: nowrap;
              align-items: stretch;
              width: 100%;
              flex-direction: row;
            }
          }

          img {
            //display: none;
            margin-left: 0px;
            width: 300px;
            height: 420px;
          }
        }
      }
    }
    .pria-temp-image {
      height: auto;
    }
  }
}

.ben-page {
  width: 100%;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //color: #00008e;
  font-family: "Lato", sans-serif;
  margin-bottom: 3rem;
  .ben-tile {
    text-align: center;
    padding: 10px 32px 0px 32px;
    height: 40px;
    // width: 150px;
    align-items: center;
    flex-wrap: wrap;
  }
}

.spacebtn {
  margin-top: 6rem;
  margin-bottom: 7rem;
  .quote {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 400;
  }
}
.quote {
  /* position: absolute;
  width: 1224px;
  height: 140px;
  left: 365px;
  top: 160px;
  */
  //top: 200px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 200%;
  /* or 70px */

  text-align: center;
  //white-space: pre;

  //color: #000000;

  /* width: 1224px;
  height: 140px;
  left: 348px;
  top: 190px;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 145%;
  

  text-align: center;*/

  color: #081149;
}
.pria-financial-text {
  margin-top: 120px;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
}
/*.hero-sectionimg {
  //position: absolute;
  //width: 76.44px;
  //height: 46.2px;
  left: 809px;
  bottom: 859.21px;
}*/
/*.register {
  width: 100vw;
  left: 600px;*/
//padding: 8px;
//background-color: $brand-primary;
// color: $mono-white;;
// align-items: center;;
//text-align: center;

// font-size: 18px;;
//line-height: 36px;

/* identical to box height, or 200% */

//text-transform: uppercase;

/* Brand/Primary */

// color: #081149;
//}
/*.textfinance {
  width: 316px;
  height: 44px;
  left: 901.44px;
  bottom: 857.41px;
  color: #081149;
}
.textpria {
  position: absolute;
  width: 91px;
  height: 44px;
  left: 702px;
  bottom: 857.41px;
  color: #081149;
}*/

@media only screen and (max-width: $sm-device) {
  .page .ftr-group .md-text-reg {
    font-size: 16px;
  }
  .page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: $brand-primary;
    .ftr-group {
      .md-text-reg {
        color: " #4F4F4F" !important;
      }
    }
    .ftr {
      img.ftr-imageMobile {
        width: 100px !important;
      }
      .lg-text-bold {
        font-size: 1.5rem !important;
      }
      .md-text-reg {
        font-size: 1rem !important;
        // margin-bottom: 1rem !important;
      }
    }

    .quote {
      line-height: 40px;
    }
    .pria-financial-text {
      margin-top: 80px;
      font-size: 12px;
      img {
        max-width: 30px;
      }
    }
    .pria-financial-text-2 {
      font-size: 6px;
    }
    .pria-financial-mobile-image {
      height: 14rem;
      .pria-temp-image {
        width: 95%;
        height: auto;
      }
    }

    .hero-section {
      display: flex;
      flex-direction: column;
      padding: 8px;
      padding-top: 0px;
      padding-left: 0px;
      align-items: flex-start;
      // background-image: url("../../assets/img/newHero1.png");
      background: rgb(224, 224, 229);
      background: -moz-linear-gradient(
        0deg,
        rgba(224, 224, 229, 1) 0%,
        rgba(154, 155, 172, 1) 100%
      );
      background: -webkit-linear-gradient(
        0deg,
        rgba(224, 224, 229, 1) 0%,
        rgba(154, 155, 172, 1) 100%
      );
      background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);

      background-repeat: no-repeat;
      background-size: auto;
      header {
        width: 100%;
        .scrolled {
          // width:100% !important;
        }
      }

      //background: #1aa6b7;

      .main {
        padding: 10px 10px;
        position: relative;
        align-self: end;
        .main-container {
          // width: 100vw;
          // height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;

          .content {
            margin: 0px;
            width: 100%;
            padding: 10px 10px;
            height: 50%;
            display: flex;
            justify-content: flex-start;

            h2 {
              white-space: break-spaces;
              text-align: center;
              font-size: 24px;
            }
            h1 {
              margin-top: 2rem;
              white-space: break-spaces;
              font-size: 28px;
              line-height: 40px;
              margin-bottom: 0px;
            }

            p {
              padding-top: 2rem;
              font-size: 16px;
              font-weight: 400;
              line-height: 36px;
              text-align: center;
              margin-right: 0px;
              text-align: left;
              // width: 300px;
              padding-bottom: 1rem;
              br {
                display: none;
              }
            }
            .btn-homepg {
              display: flex;
              width: 200px;
              .appButton.primary {
                border-radius: 18px;
              }
              // margin-right: 64px;
            }

            .input-group {
              position: relative;
              display: flex;
              flex-wrap: nowrap;
              align-items: stretch;
              width: 100%;
              flex-direction: row;
            }
          }

          .hero-img {
            display: none;
            margin-left: 0px;
            margin-top: 0px;
            width: 100%;
            height: 100%;
            align-self: center;
          }
          .sponsors-mobile {
            display: none;
            margin-left: 0px;
            margin-top: 0px;
            width: 320px;
            height: 280px;
            align-self: center;
          }
          .containerrmob {
            display: block;
          }
          .containerr {
            display: none;
          }

          .hero-img-mobile {
            display: flex;
            margin-left: 0px;
            margin-top: 0px;
            width: 90%;
            height: 100%;
            align-self: center;
            display: none;
            //margin-right: 28px;
          }
          .sponsors {
            margin-top: 4rem !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 92%;
          }
        }
      }
    }

    section {
      flex-direction: column;
      padding: 8px;
      align-items: center;
      text-align: center;

      #arrow {
        //display: block;

        //sposition: absolute;
      }

      .ftr-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .ftr {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 0;
          .illustrations {
            margin-bottom: 0px !important;
          }
        }

        .ftr2 {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 2rem;
          margin-top: 2rem;
          .lg-text-bold {
            font-size: 1.5rem;
          }
          //width: 100%;
        }

        .frame {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 8px;
          .p-block {
            white-space: break-spaces;
            h3 {
              font-weight: 700;
              font-size: 40px;
              line-height: 44px;
              color: #ff9200;
            }
            margin: 14px 0px;
          }
        }
      }
      h3 {
        white-space: break-spaces;
      }
      .logo {
        width: 100%;
        height: 60%;
        align-items: center;
      }
      .context {
        margin: 0px;
        padding: 8px;
      }
    }
    section:nth-child(2n) {
      // @extend section;
      //background-color: $grey6;
      flex-direction: column;
    }

    .faqs {
      padding: 8px;
      background-color: $grey5;
      flex-direction: column;
      text-align: center;
      h3 {
        white-space: break-spaces;
      }
      .faq {
        text-align: start;
        border-bottom: 1px solid $grey4;
        padding: 18px 12px;
        .faq-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .action {
            cursor: pointer;
          }
        }
      }
    }
    .pria-temp-image {
      height: 210px;
    }

    .meet-pria {
      .meet-pria__text {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 1.6rem;
        line-height: 44px;
        //margin-right: 0.46rem;
      }
      .meet-pria__img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
      }
      // .meet-pria__img {

      // }
      // .meet-pria__text2 {
      //   text-align: center;
      //   font-size: 2rem;
      //   font-weight: 400;
      //   white-space: break-spaces;
      // }
    }

    .end-banner {
      width: 100%;
      padding: 2rem 0.1rem; //old 1.5 0.5 rem
      background-color: $brand-primary;
      color: $mono-white;
      align-items: center;
      text-align: center;
      vertical-align: middle;

      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      h4 {
        font-size: 18px;
        margin-bottom: 1rem;
      }
      .appButton.outlined.primary {
        height: 55px; //old 1.75
        width: 200px; //old 6.5
        border-radius: 8px;
        padding: 0rem 1rem;

        .md-text-bold {
          font-size: 18px;
          line-height: 1.1rem;
          font-weight: 1100;
        }
      }
    }
    .top-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
      font-style: Normal;
      size: 16px;
      letter-spacing: 2px;
      //Text Transform: Uppercase
      line-height: 1.2em;
      font-family: Proxima Nova;
      margin-top: 2.5rem;
    }
    .top-banner1 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
      font-style: Normal;
      size: 16px;
      letter-spacing: 2px;
      //Text Transform: Uppercase
      line-height: 1.2em;
      font-family: Proxima Nova;
      //margin-top: -0.5rem;
    }
  }
}
.credit-score {
  margin-top: -2.4em;
  scroll-padding-bottom: -39rem;
  padding-bottom: 1rem;
}
.fico1 {
  margin-top: -2em;
}

.card-txtmarg1 {
  margin-top: 1.5em;
}

.card-txtmarg2 {
  margin-top: 0.6em;
}
.card-txtmarg3 {
  margin-top: 0em;
}
.card-txtmarg4 {
  margin-top: 1.5em;
  position: relative;
  // .md-text-reg{
  //   font-size: 16px;

  // }
}

.getting-startedmar {
  margin-bottom: 1rem;
}

.small-txt {
  margin-bottom: 2.5rem;
  text-align: center;
  font-size: 2.5rem;
  line-height: 44px;
  white-space: break-spaces;
}

.mid-mar {
  display: flex;
  flex-direction: column;
}

.font-hai {
  //font-family: 'Montserrat', sans-serif;
  font-family: "nunito", sans-serif;
  // line-height: 25.2px;
}

.pria-logo {
  width: 2em;
  height: 1.8em;
}
