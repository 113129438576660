@import '../../assets/sass/variables/index';

.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: $brand-primary;
  .main-container {
    display: flex;
    flex-direction: row;
    .overlay {
      width: 50vw;
      height: 100vh;
      background: #daecf3;
      padding: 104px 128px;
    }
    img {
      width: 50vw;
      height: 100vh;
    }
    .form-container {
      padding: 180px 180px;
    }
  }
}

label {
  @extend .sm-text-bold;
  white-space: nowrap;
}

a {
  color: $brand-secondary;
}
.form-check-input:checked {
  background-color: $brand-primary;
}
