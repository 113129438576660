@import "../../assets/sass/variables";
.button-style {
  // width: 30%;
  // /* display: flex; */
  // /* justify-content: space-around; */
  // margin-left: 35%;
  // margin-top: -4.6rem;
  margin-top: 0rem;
  margin-left: 0rem;
}
.userName-Trx {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  /* identical to box height, or 105% */

  color: #4f4f4f;
}
.trx-head {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 2rem 1rem 1rem 0rem;
  margin-top: -13px;
}

@media only screen and (max-width: $md-device) {
  .button-style {
    width: auto;
    /* display: flex; */
    /* justify-content: space-around; */
    margin-left: 0%;
    margin-top: 0rem;
  }
  .trx-head {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 2rem 1rem 1rem 1rem;
    justify-content: center;
  }
}

@media only screen and (max-width: 325px) {
  .trx-head {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 2rem 1rem 1rem 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.success {
  color: $state-info;
}

.failure {
  color: $state-error;
}
