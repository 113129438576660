.w-100 {
  width: 100%;
}

.bold {
  font-weight: 700;
}

.normal {
  font-weight: 400;
}

// .page {
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   color: $brand-primary;
// }

// header {
//   position: fixed;
//   padding: 16px 164px;
//   border-bottom: none;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 100vw;
// }

// main {
//   position: absolute;
//   display: flex;
//   flex-direction: column;
//   top: 115px;
//   width: 100vw;
// }
