@import "../../assets/sass/variables/index";

.navbar {
  position: fixed;
  padding: 0px 8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  //border-bottom: 1px solid $grey5;
  height: 80px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  align-content: center;

  max-width: 2560px;

  // max-width: $xl-device;
  .navbar-brand {
    h4 {
      margin: 0;
    }
  }
}

.navbar.scrolled {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  //background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.navbar-collapse {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  align-items: center;
  list-style-type: none;
}

.nav-item {
  list-style-type: none;
  //padding: 16px 24px;
  color: $brand-primary;
  cursor: pointer;
  a:link,
  :visited,
  :hover,
  :target,
  :focus-visible {
    text-decoration: none;
    color: $brand-primary;
    // padding-bottom: 8px;
    //align-self: center;
  }

  a:active:hover {
    background-color: $brand-primary;
    color: $grey6;
  }

  .nav-link.active {
    border-bottom: 4px solid $brand-primary;
    border-left: none;
  }

  // .dropdown-menu {
  //   @extend .sm-text-reg;
  //   line-height: 45px;
  //   height: fit-content;

  //   svg {
  //     width: 20px;
  //     height: 20px;
  //   }

  //   #signout {
  //     color: $brand-secondary;
  //   }
  // }

  &.active {
    border-bottom: 4px solid $brand-primary;
    border-left: none;
  }
}

@media only screen and (min-width: $lg-device) and (max-width: $xl-device) {
  .navbar {
    height: 80px;
  }

  .nav-item.active {
    border-left: none;
  }
}
@media only screen and (min-width: $md-device) and (max-width: $lg-device) {
  .navbar {
    height: 70px;
  }

  .nav-item.active {
    border-left: none;
  }
}
// @media only screen and (min-width: $sm-device) and (max-width: $md-device){
//   .navbar {
//     height: 60px;
//   }
// }
@media only screen and (max-width: $md-device) {
  .navbar {
    height: 50px;
    padding: 0px 8px;
    justify-content: "center";
  }

  .nav-item.active {
    border-left: 4px solid $brand-primary;
    border-bottom: none;
    background-color: $grey6;
    margin-top: 0.5rem;

    padding-left: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
