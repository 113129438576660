@import "/src/assets/sass/variables/index";

@mixin media($w) {
  @media screen and (min-width: $w) {
    @content;
  }
}

.about-page {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $brand-primary;
  font-family: "Lato", sans-serif;

  .frame11 {
    // background-image: url("../../assets/img/background_image.png"); img gradient
    background: rgb(224, 224, 229);
    background: -moz-linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    background: -webkit-linear-gradient(
      0deg,
      rgba(224, 224, 229, 1) 0%,
      rgba(154, 155, 172, 1) 100%
    );
    background: linear-gradient(0deg, rgba(224, 224, 229, 1) 0%, rgba(154, 155, 172, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#E0E0E5,endColorstr=#9A9BAC,GradientType=1);


    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    text-align: center;
    height: fit-content;
    width: 100%;
    overflow-wrap: break-word;
    .frame11-text {
      margin: 8rem auto;
      max-width: 1024px;
      position: relative;

      .shape-outside {
        max-height: 100%;
        width: 1rem;
        height: var(--frame-height);
        display: none;

        margin: 25px 25px 5px 0;
        float: left; //shape outside specific
        clip-path: polygon(0 0, 100% 0, 40% 100%);
        shape-outside: polygon(0 0, 100% 0, 40% 100%);

        @include media(400px) {
          display: block;
        }
        @include media(800px) {
          width: 200px;
        }

        &.right {
          float: right;
          clip-path: polygon(0 0, 100% 0, 60% 100%);
          shape-outside: polygon(0 0, 100% 0, 60% 100%);
        }
      }

      margin-bottom: 4rem;
      .frame11-text_1 {
        margin-bottom: 2.25rem;
        margin-top: 3rem;
        .h2 {
          font-size: 48px;
          font-weight: 500;
          line-height: 60px;
        }
      }
    }

    h2 {
      font-weight: 800;
      white-space: break-spaces;
      margin-bottom: 36px;
    }
    h5 {
      white-space: break-spaces;
      font-weight: 400;
    }
  }

  .section {
    padding: 60px 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    width: 100%;
    overflow-wrap: break-word;

    ul {
      list-style-type: none;
    }
    .tile {
      text-align: center;
      //padding: 64px 32px 0px 32px;
      height: fit-content;
      width: 522px;
      overflow-wrap: break-word;
    }
  }
}

@media only screen and (max-width: $lg-device) {
  .about-page {
    .frame11 {
      // padding: 128px 256px;
    }

    .section {
      .tile {
        padding: 16px;
      }
    }
  }
}

@media only screen and (max-width: $md-device) {
  .about-page {
    .frame11 {
      padding: 8px;

      h2 {
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
        white-space: break-spaces;
        margin-bottom: 36px;
      }
      h5 {
        font-size: 18px;
        white-space: break-spaces;
        font-weight: 400;
      }
    }
    .section {
      flex-direction: column;
      padding: 8px;
      height: 100%;

      .tile {
        text-align: center;
        padding: 16px 8px;
        height: fit-content;
        width: 100%;
      }
    }
  }
}
