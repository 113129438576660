@import "./../../../assets/sass/style.scss";

.verified,
.success {
  color: $state-succes;
}
.not-verified {
  color: $state-error;
}

.failed {
  color: $state-info;
}
