@import "/src/assets/sass/variables/index";

.card-display {
  background: linear-gradient(106.09deg, #000000 -19.33%, #0fb8b8 96.7%);
  box-sizing: border-box;
  border-radius: 20px;
  height: 20vh;
  //width: $xs-device;
  padding: 16px;
  color: $mono-white;
  max-width: $sm-device;
  min-width: $xs-device;
  max-height: 210px;
  cursor: pointer;
  margin: 6px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;

  .card-display-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: 400px) {
    min-height: 200px;
  }

  // &.master {
  //   background: linear-gradient(106.15deg, #3431c2 6.17%, #cd2525 102.88%);
  //   backdrop-filter: blur(80px);
  // }'
}

.card-display:nth-child(2) {
  background: linear-gradient(106.15deg, #3431c2 6.17%, #cd2525 102.88%);
  backdrop-filter: blur(80px);
}

.card-display:nth-child(odd) {
  background: linear-gradient(106.15deg, #282828 1.61%, #0b0b0b 93.48%);
  backdrop-filter: blur(80px);
}

.card-display-empty {
  border: 1px dashed #bdbdbd;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: 6px;
  height: 20vh;
  // width: 100%;
  max-width: $sm-device;
  min-width: $xs-device;
  max-height: 210px;
  min-height: 190px;

  cursor: pointer;
  padding: 0px;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  h6 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    white-space: nowrap;
    color: $grey4;
  }
}

@media only screen and (max-width: $xl-device) {
  .card-display,
  .card-display-empty {
    // height: 25vh;
    max-width: $sm-device;
    min-width: $xs-device;
    margin-top: 1.25rem;
  }
}

@media only screen and (min-width: $sm-device) and (max-width: $lg-device) {
  .card-display,
  .card-display-empty {
    // height: 23vh;
    max-width: $sm-device;
    min-width: $xs-device;
  }
}

@media only screen and (max-width: $sm-device) {
  .card-display,
  .card-display-empty {
    // max-width: $sm-device;
    // min-width: $xs-device;
    min-width: unset;
    max-width: unset;
    // height: unset;
    --height: 200px;
    height: var(--height);
    width: calc(var(--height) * 1.75);
    margin: auto;
  }
}

@media only screen and (max-width: $xs-device) {
  .card-display,
  .card-display-empty {
    // max-width: $sm-device;
    // min-width: $xs-device;
    min-width: unset;
    // height: unset;
    --height: 150px;
    height: var(--height);

    // width: calc(var(--height) * 1.625);
    margin: auto;
  }
}
