@import "../../assets/sass/variables/index";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body {
  overflow-x: hidden !important;
}

header {
  position: fixed;
  padding: 16px 50px 0px 100px;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 99;

  h4 {
    margin: 0;
  }

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    .appButton.outlined.primary {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0.06) 100%
      );
    }

    .nav-item {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}
header.scrolled {
  //background: rgba(255, 255, 255, 0.9);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: fadeIn 0.5s;
}

.active-link {
  color: 081149;
  text-decoration: underline;
}
.nav-link {
  text-decoration: none;
   &:link {
    color: #081149;
  }
}

@media only screen and (max-width: $md-device) {
  header {
    position: fixed;
    padding: 16px;
    top: 0;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    left: 0;

    &.scrolled {
      position: fixed;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      animation: fadeIn 0.5s;
      // background: #fff;
      // border-bottom: 1px solid #c6c5c5d2;
    }

    .menu {
      justify-content: space-between;
    }
  }

  .page .hero-section .main {
    padding: 1rem !important;
  }
}

@media only screen and (max-width: $sm-device) {
  header {
    position: fixed;
    padding: 1rem 27px;
    padding-right: 1rem;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    img {
      margin-right: 0rem;
    }

    .menu {
      justify-content: space-between;

      .md-text-bold {
        margin-right: 4px;
      }
    }
  }
}
@media screen and (min-width: $md-device) {
  .hamburgerMenu {
    display: none;
  }
}
