// @import '../../assets/sass/variables/_typography';

// .onboarding4{
//     margin:50px 291px 50px 291px;

//     .onboarding4__plans{
//         display:flex;
//         justify-content: space-between;
//         .onboarding4__plan{
//             display: flex;
//             flex-direction: column;
//             border:1px solid gray;
//             // .onboarding4__plan:nth-child(2){
//             //     border: 1px solid gray;
//             // }
//             .checkbox-round {
//                 width: 1.3em;
//                 height: 1.3em;
//                 background-color: white;
//                 border-radius: 50%;
//                 vertical-align: middle;
//                 border: 1px solid #ddd;
//                 appearance: none;
//                 -webkit-appearance: none;
//                 outline: none;
//                 cursor: pointer;
//                 margin-right: 20px;

//             }

//             .checkbox-round:checked {
//                 background-color: gray;
//                 content:'✔'
//             }

//             padding:20px;
//             .sm-text-reg {
//                 margin-top: 2rem;

//             }

//         }
//     }
//     .onboarding4__btn{
//         display: flex;
//         justify-content: space-between;
//         margin:64px 0px 32px 0px;
//         .goback{
//             width:232px;
//         }
//         .continue{
//             width:232px;
//         }
//     }
//     // .onboarding4__text_1{
//     //     border-bottom: 1px solid black;
//     //     display: inline;
//     // }
// }

// .container{
//     .onboarding5__text_1{
//         display:flex;
//         justify-content: center;
//         border-bottom: 1px solid black;
//         display: inline;
//     }

//     .onboarding4__text_1{
//         border-bottom: 1px solid black;
//         display: inline;
//     }

// }

@import "/src/assets/sass/variables/index";

@media only screen and (max-width: $md-device) {
  .onboarding6 {
    padding: 1rem;
  }
}

.onboarding6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .onboarding6__text {
    margin: 40px 0px 40px 0px;
  }
  .imgfile {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  img {
    max-width: fit-content;
    max-height: fit-content;
  }
  .onboarding6__btn {
    display: flex;
    justify-content: center;

    .primary {
      width: 300px;
    }
  }
}

@import "/src/assets/sass/variables/index";
.subs-page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 48px 72px;
  background-color: white;

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: white;

    padding: 48px 72px;
    position: relative;
    .onboarding4__btn {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 300px;
    }

    .form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding: 16px 0px;
      position: relative;

      .card {
        padding: 24px 12px;
        margin: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: calc(100vw / 3);
        width: unset;
        cursor: pointer;
        position: relative;

        .row {
          white-space: break-spaces;
        }

        .label {
          margin: 0px 12px;
          text-transform: capitalize;
          h6 {
            white-space: pre-wrap;
          }
        }
      }
    }

    .title {
      margin-bottom: 64px;
      align-self: center;
    }

    section {
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      padding: 0;
      margin: 24px 0;
    }

    label {
      color: $mono-grey;
    }
    .sm-text-reg {
      @extend .sm-text-reg;
      color: $mono-grey;
      padding-bottom: 12px;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .subs-page {
    width: 100%;
    padding: 0px;
    .container {
      padding: 16px;
      .form {
        align-items: center;
      }
      .onboarding4__btn {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 2rem;
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card {
          padding: 24px;
          width: 100%;
          max-width: 320px;
        }
      }
    }
  }
}

@media only screen and (min-width: $xl-device) {
  .subs-page {
    width: 100%;
    .container {
      padding: 56px 120px;
      .form-container {
        width: 900px;
      }
      .form {
        .card {
          padding: 24px;
          width: 100%;
        }
      }
    }
  }
}

.onboarding4 {
  .form-container {
    width: 100%;
  }
}
