@import "../../assets/sass/variables/index";
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: 80vw;
}
.sidebar-item-spacer {
  margin-top: 0.5rem;
  // margin-left: -;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  // margin-top: 0.5rem;
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.offcanvas {
  width: 100%;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.5);

  height: calc(var(--vh) * 100);
  max-height: 100vh;

  & > div {
    width: 50%;

    @media screen and (max-width: $sm-device) {
      width: 80%;
      // padding-top: 1.18rem;
    }
  }
}

.offcanvas-header {
  background: #fff;
  padding: 0.5rem 1rem;
}

.offcanvas-body {
  padding: 0;

  background: #fff;

  a {
    text-decoration: none;
    width: 100%;
  }
}

.offcanvas-footer {
  border: 1px solid $grey5;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px;
  .nrm-text-bold.userName {
    max-width: 10rem;
    word-wrap: break-word;
  }

  div {
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  .logoutIconAndText {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    .logout-txt {
      margin-left: 0px;
      margin-right: 0.5rem;
      color: red;
    }
  }
}

@media only screen and (max-width: $md-device) {
  .nav-item {
    align-self: flex-start;
    padding: 4px 8px;
    width: 100%;
    padding-left: 1.3rem;
  }
}
