@import "/src/assets/sass/variables/index";
.checkout-page {
  position: relative;
  min-height: auto;
  display: flex;
  flex-direction: column;
  .container {
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: fit-content;
    padding: 5% 20%;
    position: relative;

    .title {
      text-align: center;
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px;
      position: relative;

      .card {
        padding: 32px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //align-items: center;

        .order-alignment {
          align-items: stretch;
          flex: none;
        }
        .onboarding5__btn {
          display: flex;
          justify-content: center;
          .marg-btnob {
            margin-right: 0.3rem;
          }
        }
        .form {
          display: flex;
          position: relative;
        }
      }

      .md-text-bold {
        margin-bottom: 8px;
      }

      .row {
        position: relative;
      }
    }
  }
}
.align-checkout-amts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
}

@media only screen and (max-width: $md-device) {
  .checkout-page {
    .container {
      padding: 16px;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
          .onboarding5__btn {
            display: flex;
            flex-direction: column-reverse;
            row-gap: 2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $xl-device) {
  .checkout-page {
    .container {
      padding: 5% 20%;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 950px) and (max-width: 1250px) {
  .checkout-page {
    .container {
      padding: 60px 210px;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $sm-device) {
  .checkout-page {
    .container {
      padding: 3px;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
          .onboarding5__btn {
            display: flex;
            flex-direction: column-reverse;
            row-gap: 2rem;
          }
        }
      }
    }
  }
}
