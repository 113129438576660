@import '../variables/index';
.appButton {
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border: 2px solid;
  white-space: nowrap;
  font-size: 14px;

  &.primary.borderless.grey {
    background-color: $grey6;
  }

  &.primary {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $mono-white;
  }
  &.secondary {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
    color: $mono-white;
  }
  &.tertiary {
    background-color: $state-error;
    border-color: $state-error;
    color: $mono-white;
  }
  &.success {
    background-color: $state-succes;
    border-color: $state-succes;
    color: $mono-white;
  }

  &[disabled] {
    background-color: $grey5;
    color: $mono-white;
    border: none;
  }
  &.outlined.primary {
    background-color: $mono-white;
    color: $brand-primary;
  }
  &.outlined.secondary {
    background-color: $mono-white;
    color: $brand-secondary;
  }
  &.outlined.tertiary {
    background-color: $mono-white;
    color: $state-error;
  }
  &.borderless.primary {
    background-color: $mono-white;
    color: $brand-primary;
    border: none;
    padding: 0;
    margin: 0;
  }
  &.borderless.secondary {
    background-color: $mono-white;
    color: $brand-secondary;
    border: none;
    padding: 0;
    margin: 0;
  }
  &.borderless.tertiary {
    background-color: $mono-white;
    color: $state-error;
    border: none;
    padding: 0;
    margin: 0;
  }
}
#remove-btn {
  color: $grey3;
  font-weight: 700;
}

.appButton-sm {
  @extend .appButton;
  width: 193px;
  height: 48px;
}
.appButton-nrm {
  @extend .appButton;
  width: 275px;
  height: 56px;
}
.appButton-md {
  @extend .appButton;
  width: 310px;
  height: 62px;
}
.appButton-lg {
  @extend .appButton;
  width: 344px;
  height: 68px;
}

@media (max-width: 1024px) {
  .appButton {
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    border: 2px solid;
    white-space: nowrap;
  }
}

@media (max-width: 500px) {
  .appButton {
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    border: 2px solid;
    white-space: nowrap;
  }
}
