@import "/src/assets/sass/variables/index";
.checkout-page {
  position: relative;
  min-height: auto;
  display: flex;
  flex-direction: column;
  .container {
    background-color: $grey6;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: fit-content;
    padding: 5% 20%;
    position: relative;
    background-color: $grey6;

    .title {
      text-align: center;
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px;
      position: relative;

      .checkbox {
        border: 1px solid $grey5;
        border-radius: 5px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin: 4px 0px;
      }

      .card {
        padding: 32px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //align-items: center;
        align-content: center;
        justify-items: center;
        .form {
          display: flex;
          position: relative;
        }
        .order-alignment {
          align-items: stretch;
          flex: none;
        }
      }

      .md-text-bold {
        margin-bottom: 8px;
      }

      .row {
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: $sm-device) {
  .checkout-page {
    .container {
      padding: 16px;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: $xl-device) {
  .checkout-page {
    .container {
      padding: 56px 720px;
      .section {
        padding: 8px;
        .card {
          padding: 16px;
        }
      }
    }
  }
}

.pricc {
  display: flex;
  //margin-left: 15%;
  flex-direction: row;
  justify-content: center;
}

.pricc2 {
  // margin-left: -15%;
}

.pricc1 {
  margin-left: -15%;
}

.align-checkout-amts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
}
