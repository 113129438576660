@import '/src/assets/sass/variables/index';

.MuiBox-root{
   // height: 100vh;
} 

.MuiStepConnector-line{
    display: none;
}

.MuiStepper-root{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    .MuiStepIcon-active{
        color:black
    }
    .MuiStepIcon-text{
        color:black


        
    }
    
}

@media only screen and (min-width: $xl-device){
    .MuiStepper-root{
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        .MuiStepIcon-active{
            color:black
        }
        .MuiStepIcon-text{
            color:black
    
    
            
        }
        
    }

}