@import '/src/assets/sass/variables/index';

.container {
  background-color: $grey6;
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  padding: 16px 20vw;

  .title {
    margin-bottom: 64px;
    align-self: center;
  }

  section {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding: 0;
    margin: 24px 0;
  }

  .card {
    padding: 40px 56px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: inherit;
    }
  }

  label {
    color: $mono-grey;
  }
  .sm-text-reg {
    @extend .sm-text-reg;
    color: $mono-grey;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: $md-device) {
  .container {
    padding: 8px;
    .card {
      margin: 8px 0px;
    }
  }
}
