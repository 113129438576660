@import "../../assets/sass/variables/index";

.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(26, 166, 183, 0.1);
  padding: 16px 156px;
  background-image: url("../../assets/img/background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $brand-primary;
  position: relative;

  header {
    position: fixed;
    padding: 16px 164px;
    border-bottom: 1px solid $grey5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    background: $mono-white;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .main {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 70px;
  }

  section {
    padding: 1rem 8rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
    flex-wrap: nowrap;
    position: relative;
    margin-bottom: 1.5rem;
    overflow-x: hidden;

    .view {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-content: center;
    }

    .card-section {
      max-width: $sm-device;
      min-width: $xs-device;
      display: flex;
      flex-direction: column;
      margin-right: 50px;
      justify-content: space-between;
      height: fit-content;
      flex-wrap: wrap;
      max-width: $sm-device;
      min-width: $xs-device;
    }
    .activity-section {
      width: 65%;
      display: flex;
      flex-direction: column;
      height: fit-content;
      flex-grow: 1;
      //margin-left: 20px;

      // .dropdown {
      //   padding: 0px 8px;
      //   width: fit-content;
      //   border: 1px solid $grey4;
      //   border-radius: 4px;
      // }

      // .dropdown-menu {
      //   margin: 0.5rem -0.5rem;
      // }

      // .dropdown-item {
      //   display: flex;
      //   height: 2rem;
      //   align-items: center;

      //   &.active {
      //     background-color: $brand-primary;
      //     color: $mono-white;
      //   }
      //   &:hover,
      //   &:active {
      //     background-color: #e9ecef;
      //     color: $mono-black;
      //   }
      // }
    }

    .heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

// .modal-content {
//   background: rgba(33, 37, 41, 0.3);
//   border: rgba(33, 37, 41, 0);
//   backdrop-filter: blur(100px);
// }

.modal-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $lg-device) and (max-width: $xl-device) {
  section {
    width: 100%;
    //padding: 16px 96px;
  }
  .card-section {
    max-width: $sm-device;
    min-width: $xs-device;
  }
  .activity-section {
    max-width: 77%;
  }
}

@media only screen and (min-width: $md-device) and (max-width: $lg-device) {
  section {
    //padding: 16px 96px;
  }
  .card-section {
    max-width: 30%;

    .funding-info,
    .subscription-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 12px 0 12px 0;
      width: 100%;
      align-items: flex-start;

      .card-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .activity-section {
    max-width: 70%;
  }
}

// @media only screen and (min-width: $sm-device) and (max-width: $md-device) {
//   .card-section {
//     max-width: 20%;
//     height: fit-content;
//   }
//   .activity-section {
//     max-width: 80%;
//   }
// }

@media only screen and (max-width: $md-device) {
  .banner {
    // padding: 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // max-width: 100%;
    // align-items: center;
    // text-align: center;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    text-align: left;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard-page {
    .main {
      position: relative;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      width: 100vw;
    }
    section {
      padding: 8px;

      .view {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
      }

      .card-section {
        // max-width: $sm-device;
        // min-width: $xs-device
        min-width: unset;
        width: 100%;
        height: fit-content;
        justify-items: center;
        margin-right: 0;
        align-self: center;
        row-gap: 1.5rem;

        .carousel {
          height: fit-content;
          position: relative;
          flex-direction: row-reverse;

          .carousel-inner {
            height: fit-content;
            position: relative;
            .carousel-item {
              height: fit-content;
              .root {
                background: linear-gradient(106.09deg, #000000 -19.33%, #0fb8b8 96.7%);
                backdrop-filter: blur(80px);
              }
            }
            .carousel-item:nth-child(3) {
              .card-display {
                background: linear-gradient(106.09deg, #000000 -19.33%, #0fb8b8 96.7%);
                backdrop-filter: blur(80px);
              }
            }
            .carousel-item:nth-child(1) {
              .card-display {
                background: linear-gradient(106.15deg, #3431c2 6.17%, #cd2525 102.88%);
                backdrop-filter: blur(80px);
              }
            }
            .carousel-item:nth-child(2) {
              .card-display {
                background: linear-gradient(106.15deg, #282828 1.61%, #0b0b0b 93.48%);
                backdrop-filter: blur(80px);
              }
            }
          }

          .carousel-indicators {
            margin: -2rem 15%; //TLBR
            li {
              background-color: black;
            }
          }

          .carousel-control-next,
          .carousel-control-prev {
            background: none;
            align-items: end;
            height: 50%;
          }
        }
      }
      .activity-section {
        width: 100%;
        margin-top: 32px;
      }
    }
  }

  .modal-body {
    padding: 1rem;
  }
}

@media only screen and (max-width: $xs-device) {
  .card-section {
    max-width: 400px !important;
  }
  .card-display {
    min-width: unset;
  }
  .card-display-empty {
    min-width: unset;
  }
}
